import axios from "../../core/axios";
import { assertSuccess, paramsParser } from "../../core";
import { Headers } from "../../index";
import type { GenericListParams } from "../../types";
import type { CollectionResult, Result } from "../../types/index";
import type {
  TitleApprovalDecision,
  TitleApprovalEntity,
} from "../../types/title/approval";

export type GetTitleApprovalsParams = GenericListParams & {
  includes?: TitleApprovalEntity["relationships"][number]["type"][];
  order?: Partial<Record<"createdAt" | "updatedAt", "asc" | "desc">>;
  titleId?: string;
  organizationId?: string;
  userId?: string;
  decisions?: TitleApprovalDecision[];
  createdAtSince?: string;
  createdAtBefore?: string;
  updatedAtSince?: string;
  updatedAtBefore?: string;
};

export type ResolveTitleApprovalBody = {
  version: number;
  approval: "approve" | "auto_approve" | "reject" | "reset";
  staffDetails?: string;
};

export class TitleApproval {
  static async getTitleApprovals(
    params: GetTitleApprovalsParams,
    auth: string,
  ) {
    const response = await axios.get<CollectionResult<TitleApprovalEntity>>(
      "/title/approval" + paramsParser(params),
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(response.data);
  }

  static async viewTitleApproval(
    id: string,
    includes: TitleApprovalEntity["relationships"][number]["type"],
    auth: string,
  ) {
    const response = await axios.get<Result<TitleApprovalEntity>>(
      `/title/approval/${id}` + paramsParser({ includes }),
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(response.data);
  }

  static async resolveTitleApproval(
    id: string,
    body: ResolveTitleApprovalBody,
    auth: string,
  ) {
    const response = await axios.post<Result<TitleApprovalEntity>>(
      `/title/approval/${id}`,
      body,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(response.data);
  }
}
